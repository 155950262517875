import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.fill.js";
export default {
  props: ['certData', 'loc'],
  data: function data() {
    return {};
  },
  computed: {
    appStore: function appStore() {
      return useAppStore();
    },
    locale: function locale() {
      return this.loc || this.$i18n.locale;
    },
    cert: function cert() {
      var def = {
        givenName: '',
        familyName: '',
        certDate: 'Не вказано',
        certId: 'Не вказано',
        averageMark: 0,
        separatedMark: {
          theory: 0,
          pratice: 0,
          creativity: 0
        },
        position: 24000,
        courseName: 'Тест сертифіката',
        courseNames: {},
        url: 'http://cases.media',
        company: null,
        companySingle: false,
        pattern: '/images/creative-practice/certificate/certificate-pattern-full.png',
        patternLight: false,
        logo: '/images/creative-practice/certificate/cp-logo-s.svg',
        logoBg: '#0E1014',
        type: 'course'
      };
      return Object.assign(def, this.certData);
    },
    courseName: function courseName() {
      return this.cert.courseNames[this.loc] || this.cert.courseName;
    },
    positionImage: function positionImage() {
      if (!this.certData.position || this.certData.position > 25) return null;
      if (this.certData.position > 10) {
        return "/images/creative-practice/certificate/top25@2x-".concat(this.loc, ".png");
      }
      if (this.certData.position > 5) {
        return "/images/creative-practice/certificate/top10@2x-".concat(this.loc, ".png");
      }
      return "/images/creative-practice/certificate/top5@2x-".concat(this.loc, ".png");
    },
    SFFontLoading: function SFFontLoading() {
      return this.appStore.SFFontLoading;
    },
    SFFontLoaded: function SFFontLoaded() {
      return this.appStore.SFFontLoaded;
    },
    qriousLoading: function qriousLoading() {
      return this.appStore.qriousLoading;
    },
    qriousLoaded: function qriousLoaded() {
      return this.appStore.qriousLoaded;
    }
  },
  watch: {
    locale: function locale() {
      this.createCert();
    },
    qriousLoaded: function qriousLoaded() {
      this.createCert();
    },
    SFFontLoaded: function SFFontLoaded() {
      this.createCert();
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (!this.qriousLoaded && !this.qriousLoading) {
      this.appStore.setState({
        key: 'qriousLoading',
        value: true
      });
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "/js/qrious.min.js";
      document.head.append(script);
      script.onload = function () {
        _this.appStore.setState({
          key: 'qriousLoaded',
          value: true
        });
      };

      // TODO: Add Error UI
      script.onerror = function () {
        _this.appStore.setState({
          key: 'qriousLoading',
          value: false
        });
      };
    }
    this.appStore.loadSFFonts();
    this.createCert();
  },
  methods: {
    createCert: function createCert() {
      var _this2 = this;
      if (!this.SFFontLoaded || !this.qriousLoaded) return;
      var canvas = this.$el;
      canvas.width = 1920;
      canvas.height = 1080;
      var r2d = setupCanvas(canvas);
      var bgImage = new Image();
      var logo = new Image();
      bgImage.crossOrigin = "anonymous";
      logo.crossOrigin = "anonymous";
      function setupCanvas(canvas) {
        // Get the device pixel ratio, falling back to 1.
        var dpr = window.devicePixelRatio || 1;
        if (dpr > 2) dpr = 2;
        // Get the size of the canvas in CSS pixels.
        // var rect = canvas.getBoundingClientRect();
        // Give the canvas pixel dimensions of their CSS
        // size * the device pixel ratio.
        canvas.width = 1920 * dpr;
        canvas.height = 1080 * dpr;
        var ctx = canvas.getContext('2d');
        // Scale all drawing operations by the dpr, so you
        // don't have to worry about the difference.
        ctx.scale(dpr, dpr);
        return ctx;
      }
      bgImage.onload = function () {
        // cover image
        r2d.save();
        r2d.beginPath();
        r2d.rect(0, 0, 1120, 1080);
        r2d.clip();
        r2d.drawImage(bgImage, 0, 0);
        r2d.restore();
        r2d.fillStyle = _this2.cert.patternLight ? '#000000' : '#FFFFFF';
        r2d.font = '64px SF UI Display';
        r2d.fillText(_this2.$t('certificate.cert', _this2.locale), 160, 148);
        r2d.font = '30px SF UI Display';
        var certFor = _this2.cert.type === 'event' ? 'certificate.certForWorkshop' : 'certificate.certFor';
        r2d.fillText(_this2.$t(certFor, _this2.locale), 160, 386);
        var _fitCourseName = function fitCourseName(size) {
          r2d.font = "bold ".concat(size, "px SF UI Display");
          var nameWidth = r2d.measureText(_this2.courseName).width;
          if (nameWidth > 900) {
            return _fitCourseName(size - 1);
          } else {
            return size;
          }
        };
        var coursSize = _fitCourseName(64);
        r2d.font = "bold ".concat(coursSize, "px SF UI Display");
        r2d.fillText(_this2.courseName, 160, 481);
        if (!_this2.cert.company) {
          r2d.font = '30px SF UI Display';
          r2d.fillText(_this2.$t('certificate.by', _this2.locale), 160, 542);
          r2d.font = 'bold 30px SF UI Display';
          r2d.fillText(_this2.$t('certificate.byCreativePractice', _this2.locale), 210, 542);
        } else {
          r2d.font = '30px SF UI Display';
          r2d.fillText(_this2.$t('certificate.by', _this2.locale), 160, 542);
          r2d.font = 'bold 30px SF UI Display';
          r2d.fillText(_this2.cert.company, _this2.locale == 'uk' ? 210 : 200, 542);
          if (!_this2.cert.companySingle) {
            r2d.font = '30px SF UI Display';
            r2d.fillText(_this2.$t('certificate.and', _this2.locale), 160, 603);
            r2d.font = 'bold 30px SF UI Display';
            r2d.fillText(_this2.$t('certificate.byCreativePractice', _this2.locale), _this2.locale == 'en' ? 220 : 200, 603);
          }
        }
        r2d.font = '18px SF UI Display';
        r2d.fillText(_this2.$t('certificate.id', _this2.locale), 160, 953);
        r2d.font = '36px SF UI Display';
        r2d.fillText(_this2.cert.certId, 160, 1000);
        r2d.font = '18px SF UI Display';
        r2d.fillText(_this2.$t('certificate.date', _this2.locale), 800, 953);
        r2d.font = '36px SF UI Display';
        r2d.fillText(_this2.cert.certDate, 800, 1000);
        r2d.beginPath();
        r2d.arc(880, 160, 80, 0, 2 * Math.PI);
        r2d.fillStyle = _this2.cert.logoBg;
        r2d.fill();
        logo.src = _this2.cert.logo;
        logo.onload = function () {
          // r2d.drawImage(logo, 820, 133, 119, 54);
          r2d.drawImage(logo, 820, 100, 119, 119);
        };
      };
      bgImage.src = this.cert.pattern;

      // Right side background
      r2d.fillStyle = 'rgb(255, 255, 255)';
      r2d.fillRect(1120, 0, 800, 760);

      // Bottom rigght
      r2d.fillStyle = '#F5F7FA';
      r2d.fillRect(1120, 760, 800, 320);
      r2d.fillStyle = '#0E1014';
      var _fitText = function fitText(size) {
        r2d.font = "".concat(size, "px SF UI Display");
        var nameWidth = r2d.measureText(_this2.cert.givenName).width;
        var familyWidth = r2d.measureText(_this2.cert.familyName).width;
        if (nameWidth > 425 || familyWidth > 425) {
          return _fitText(size - 1);
        } else {
          return size;
        }
      };
      var size = _fitText(64);
      r2d.font = "".concat(size, "px SF UI Display");
      r2d.fillText(this.cert.givenName, 1280, 144);
      r2d.fillText(this.cert.familyName, 1280, 144 + size + size / 4);
      r2d.font = '30px SF UI Display';
      var resultText = this.cert.type === 'event' ? 'certificate.resultWorkshop' : 'certificate.result';
      r2d.fillText(this.$t(resultText, this.locale), 1281, 373);
      r2d.font = '64px SF UI Display';
      r2d.textAlign = "center";
      r2d.fillText(this.cert.averageMark, 1400, 553);
      r2d.textAlign = "left";
      r2d.font = '18px SF UI Display';
      if (typeof this.cert.separatedMark.theory != 'undefined') {
        r2d.fillText(this.$t('certificate.theory', this.locale), 1632, 444);
      }
      if (typeof this.cert.separatedMark.practice != 'undefined') {
        r2d.fillText(this.$t('certificate.practice', this.locale), 1632, 525);
      }
      if (typeof this.cert.separatedMark.creativity != 'undefined') {
        r2d.fillText(this.$t('certificate.creative', this.locale), 1632, 606);
      }
      r2d.fillStyle = '#878D99';
      if (typeof this.cert.separatedMark.theory != 'undefined') {
        r2d.fillText("".concat(this.cert.separatedMark.theory, " ").concat(this.$t('certificate.of100', this.locale)), 1632, 465);
      }
      if (typeof this.cert.separatedMark.practice != 'undefined') {
        r2d.fillText("".concat(this.cert.separatedMark.practice, " ").concat(this.$t('certificate.of100', this.locale)), 1632, 546);
      }
      if (typeof this.cert.separatedMark.creativity != 'undefined') {
        r2d.fillText("".concat(this.cert.separatedMark.creativity, " ").concat(this.$t('certificate.of100', this.locale)), 1632, 627);
      }

      // Separators
      if (typeof this.cert.separatedMark.practice != 'undefined' && typeof this.cert.separatedMark.theory != 'undefined') {
        r2d.strokeStyle = '#DDE1EB';
        r2d.moveTo(1600, 490);
        r2d.lineTo(1840, 490);
        r2d.stroke();
      }
      if (typeof this.cert.separatedMark.creativity != 'undefined') {
        r2d.moveTo(1600, 571);
        r2d.lineTo(1840, 571);
        r2d.stroke();
      }
      r2d.fillStyle = '#878D99';
      r2d.fillText(this.$t('certificate.footer1', this.locale), 1281, 917);
      r2d.fillText(this.$t('certificate.footer2', this.locale), 1281, 941);
      r2d.fillText(this.$t('certificate.footer3', this.locale), 1281, 965);
      r2d.fillStyle = '#0E1014';
      r2d.fillText("HTTPS://CASES.MEDIA/CERT/".concat(this.cert.certId), 1281, 1000);

      // Position Image
      if (this.positionImage) {
        var position = new Image();
        position.onload = function () {
          r2d.drawImage(position, 1740, 80, 100, 100);
        };
        position.src = this.positionImage;
      }

      // Legend marks
      if (typeof this.cert.separatedMark.theory != 'undefined') {
        var theory = new Image();
        theory.src = '/images/creative-practice/certificate/theory-legend.svg';
        theory.onload = function () {
          r2d.drawImage(theory, 1603, 429, 18, 18);
        };
      }
      if (typeof this.cert.separatedMark.practice != 'undefined') {
        r2d.beginPath();
        r2d.arc(1612, 519, 10, 0, 2 * Math.PI);
        r2d.fillStyle = '#F47881';
        r2d.fill();
      }
      if (typeof this.cert.separatedMark.creativity != 'undefined') {
        var creative = new Image();
        creative.src = '/images/creative-practice/certificate/creative-legend.svg';
        creative.onload = function () {
          r2d.drawImage(creative, 1603, 591, 20, 20);
        };
      }

      // Circle placeholders
      if (typeof this.cert.separatedMark.theory != 'undefined') {
        r2d.beginPath();
        r2d.arc(1400, 531, 114, 0, 2 * Math.PI);
        r2d.lineWidth = 12;
        r2d.strokeStyle = 'rgba(66, 184, 164, 0.16)';
        r2d.stroke();
      }
      if (typeof this.cert.separatedMark.practice != 'undefined') {
        r2d.beginPath();
        r2d.arc(1400, 531, 98, 0, 2 * Math.PI);
        r2d.lineWidth = 12;
        r2d.strokeStyle = 'rgba(244, 120, 129, 0.16)';
        r2d.stroke();
      }
      if (typeof this.cert.separatedMark.creativity != 'undefined') {
        r2d.beginPath();
        r2d.arc(1400, 531, 82, 0, 2 * Math.PI);
        r2d.lineWidth = 12;
        r2d.strokeStyle = 'rgba(193, 88, 245, 0.16)';
        r2d.stroke();
      }

      // Filled circles
      r2d.lineCap = "round";
      if (typeof this.cert.separatedMark.theory != 'undefined') {
        r2d.beginPath();
        r2d.arc(1400, 531, 114, this.rad(270), this.percentToRad(this.cert.separatedMark.theory));
        r2d.lineWidth = 12;
        r2d.strokeStyle = 'rgba(66, 184, 164, 1)';
        r2d.stroke();
      }
      if (typeof this.cert.separatedMark.practice != 'undefined') {
        r2d.beginPath();
        r2d.arc(1400, 531, 98, this.rad(270), this.percentToRad(this.cert.separatedMark.practice));
        r2d.lineWidth = 12;
        r2d.strokeStyle = 'rgba(244, 120, 129, 1)';
        r2d.stroke();
      }
      if (typeof this.cert.separatedMark.creativity != 'undefined') {
        r2d.beginPath();
        r2d.arc(1400, 531, 82, this.rad(270), this.percentToRad(this.cert.separatedMark.creativity));
        r2d.lineWidth = 12;
        r2d.strokeStyle = 'rgba(193, 88, 245, 1)';
        r2d.stroke();
      }
      var qrImage = new Image();
      qrImage.onload = function () {
        r2d.drawImage(qrImage, 1680, 850, 160, 160);
      };
      var protocol = window.location.protocol;
      var host = window.location.hostname;
      var qr = new QRious({
        value: "".concat(protocol, "//").concat(host, "/cert/").concat(this.cert.certId),
        backgroundAlpha: 0,
        size: 320
      });
      qrImage.src = qr.toDataURL();
    },
    rad: function rad(deg) {
      return Math.PI / 180 * deg;
    },
    percentToRad: function percentToRad(percent) {
      return this.rad(270) + this.rad(360 * percent / 100);
    }
  }
};